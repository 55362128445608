<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      class="assest-save-form"
      label-width="100px"
    >
      <el-form-item
        label="名称"
        prop="fieldName"
        :rules="[ { required: true, message: '字段名称不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.fieldName"
          maxlength="40"
        />
      </el-form-item>
      <el-form-item
        label="英文名称"
        prop="fieldEnName"
      >
        <el-input
          v-model="form.fieldEnName"
          maxlength="40"
        />
      </el-form-item>
      <el-form-item
        label="类型"
        prop="classId"
        :rules="[ { required: true, message: '类型不能为空', trigger: 'change' }]"
      >
        <el-cascader
          v-model="form.classId"
          :options="classOptions"
          style="width: 100%;"
          popper-class="cas-class"
          :props="{checkStrictly: true, expandTrigger: 'hover', value: 'id', label: 'label' }"
        />
      </el-form-item>
      <el-form-item
        label="标签"
        prop="labelIdList"
        :rules="[ { required: false, message: '标签不能为空', trigger: '' }]"
      >
        <el-select
          v-model="form.labelIdList"
          multiple
          placeholder="请选择标签"
        >
          <el-option
            v-for="(item,index) in labelOptions"
            :key="index"
            :label="item.labelName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="是否敏感"
        prop="sensitive"
      >
        <el-radio
          v-model="form.sensitive"
          :label="true"
        >是</el-radio>
        <el-radio
          v-model="form.sensitive"
          :label="false"
        >否</el-radio>
      </el-form-item>
      <el-form-item
        label="安全级别"
        prop="securityLevel"
        :rules="[{ required: true, message: '安全级别不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.securityLevel"
          placeholder="请选择安全级别"
        >
          <el-option
            v-for="(item,index) in [1,2,3,4,5]"
            :key="index"
            :label="item+ '级'"
            :value="item"
          />
        </el-select>
        <el-tooltip
          effect="light"
          placement="top"
        >
          <div
            slot="content"
            style="whiteSpace: pre-line"
          >数据安全级别从高到低依次为5级、4级、3级、2级和1级</div>
          <i
            class="el-icon-info"
            style="font-size: 12px;position: absolute;top: 10px;right: -18px"
          />
        </el-tooltip>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          v-model="form.remark"
          placeholder="请输入内容"
          type="textarea"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import {
  CreateField, GetClassOptions, GetLabelOptions, UpdateField, QueryInfo,
} from '@/api/system/privacy/privacy'

export default {
  data() {
    return {
      treeSelectProps: {
        value: 'id', // ID字段名
        label: 'className', // 显示名称
        children: 'children', // 子级字段名
      },
      loading: false,
      dialogVisible: false,
      title: '新增隐私字段',
      groupOptions: [],
      classOptions: [],
      gradeOptions: [],
      labelOptions: [],
      enterpriseId: 1,
      form: {
        id: 0,
        classId: 0,
        securityLevel: undefined,
        fieldName: '',
        fieldEnName: '',
        remark: '',
        sensitive: undefined,
        labelIdList: [],
      },
    }
  },
  created() {

  },
  methods: {
    init() {
      this.dialogVisible = true
      this.loading = false
      this.$nextTick(() => {
        this.getClassOptions()
        this.getLabelOptions()
        this.queryInfo()
      })
    },
    queryInfo() {
      if (this.form.id !== 0) {
        QueryInfo(this.form.id).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.form = resData.data
            if (this.form.securityLevel === 0) {
              this.form.securityLevel = undefined
            }
          }
        })
      }
    },
    getClassOptions() {
      GetClassOptions().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.classOptions = resData.data
        }
      })
    },
    getLabelOptions() {
      GetLabelOptions().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.labelOptions = resData.data
        }
      })
    },
    dialogOpen() {
      if (this.form.id) {
        this.title = '修改隐私字段'
      } else {
        this.title = '新增隐私字段'
      }
      this.getClassOptions()
      this.getLabelOptions()
    },
    dialogClose() {
      this.form = {
        id: 0,
        classId: 0,
        securityLevel: undefined,
        fieldName: '',
        fieldEnName: '',
        remark: '',
        sensitive: undefined,
        labelIdList: [],
      }
      // this.$refs.form.resetFields()
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        // eslint-disable-next-line no-empty
        if (valid) {
          this.loading = true
          if (typeof this.form.classId === 'object') {
            this.form.classId = this.form.classId.splice(-1)[0]
          }
          if (this.form.id) {
            UpdateField(this.form).then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                this.$emit('getList')
              } else {
                error(resData.msg)
              }
            })
          } else {
            this.form.id = 0
            CreateField(this.form).then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                this.$emit('getList')
              } else {
                error(resData.msg)
              }
            })
          }
          this.loading = false
        }
      })
    },
  },
}
</script>

<style>
.assest-save-form {
  padding-right: 10px;
}
.assest-save-form .el-icon-question {
  position: absolute;
  top: 14px;
  right: -20px;
}
</style>
