<template>
  <div class="privacy-field-manage">
    <PageIntroduce
      :title="'隐私字段管理'"
      :desc="'对企业收集到的个人信息字段进行分类分级标记和处理。'"
      :tips="'中国个人信息保护法 第五十一条'"
    />
    <el-tabs
      v-model="activeName"
      type="border-card"
    >
      <!--      <el-tab-pane-->
      <!--        v-for="(item,index) in navMenus"-->
      <!--        :key="index"-->
      <!--        :label="item.label"-->
      <!--        :lazy="true"-->
      <!--        :name="String(index+1)"-->
      <!--      >-->
      <!--        <FieldStatistics v-if="activeName === '1'" />-->
      <!--        <privacyFiledView v-if="activeName === '2'" />-->
      <!--        <privacyLabel v-if="activeName === '3'" />-->
      <!--      </el-tab-pane>-->
      <el-tab-pane
        label="字段统计"
        :lazy="true"
        name="1"
      >
        <FieldStatistics v-if="activeName === '1'" />
      </el-tab-pane>
      <el-tab-pane
        label="隐私字段"
        :lazy="true"
        name="2"
      >
        <privacyFiledView v-if="activeName === '2'" />
      </el-tab-pane>
      <el-tab-pane
        label="数据标签"
        :lazy="true"
        name="3"
      >
        <privacyLabel v-if="activeName === '3'" />
      </el-tab-pane>
    </el-tabs>
  </div>

</template>

<script>

import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'
import privacyFiledView from './privacyFieldView.vue'
import privacyLabel from './privacyLabel.vue'
import FieldStatistics from './fieldStatistics/FieldStatistics.vue'

export default {
  components: {
    privacyLabel,
    PageIntroduce,
    privacyFiledView,
    FieldStatistics,
  },
  data() {
    return {
      activeName: '1',
      navMenus: [],
      fullMenus: ['字段统计', '隐私字段', '数据标签'],
    }
  },
  watch: {
    activeName() {
      sessionStorage.setItem('activeName', this.activeName)
    },
  },
  created() {
    const twoLevelMenus = localStorage.getItem('twoLevelMenus')
    if (twoLevelMenus) {
      const menus = JSON.parse(twoLevelMenus)
      menus.forEach(d => {
        if (this.fullMenus.indexOf(d.name) !== -1) {
          this.navMenus.push({
            label: d.name,
          })
        }
      })
    }
    this.activeName = sessionStorage.getItem('activeName') || '1'
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.setItem('activeName', '1')
    next()
  },
}
</script>

<style scoped>
.privacy-field-manage {
  height: 100%;
}
</style>
