<template>
  <div
    id="chart"
    style="width: 100%;height: 100%"
  >
    <el-empty
      v-show="option.series.data.length === 0"
      description="暂无数据"
    />
  </div>

</template>

<script>
// import ECharts from 'vue-echarts'
// import 'echarts/lib/component/tooltip'
// import 'echarts/lib/component/legend'
// import 'echarts/lib/chart/sunburst'
import { GetClassSunburst } from '@/api/system/privacy/privacy'
import * as echarts from 'echarts'

export default {
  name: 'FieldSunburst',
  // components: {ECharts },
  data() {
    return {
      option: {
        series: {
          type: 'sunburst',
          // highlightPolicy: "ancestor",
          data: [],
          radius: [0, '210%'],
          sort: null,
          levels: [
            {},
            {
              r0: '15%',
              r: '35%',
              itemStyle: {
                color: '#2D9BFF',
              },
              label: {
                rotate: 'tangential',
              },
            },
            // '#2D9BFF', '#92FFFF', '#ACFF96', '#7583FF', '#73C3FF',
            {
              r0: '36%',
              r: '66%',
              itemStyle: {
                color: '#39BFFF',
              },
              label: {
                align: 'center',

              },
            },
            {
              r0: '67%',
              r: '69%',
              itemStyle: {
                color: '#4F78FE',
              },
              label: {
                position: 'outside',
                padding: 3,
                silent: false,
              },

            },
          ],
        },
      },
      loadingStyle: {
        text: '数据正在加载...',
        textColor: '#fff',
        color: '#0d40b2',
        maskColor: 'rgba(255, 255, 255, 0)',
        zlevel: 0,

        // 字体大小。从 `v4.8.0` 开始支持。
        fontSize: 12,
        // 是否显示旋转动画（spinner）。从 `v4.8.0` 开始支持。
        showSpinner: true,
        // 旋转动画（spinner）的半径。从 `v4.8.0` 开始支持。
        spinnerRadius: 10,
        // 旋转动画（spinner）的线宽。从 `v4.8.0` 开始支持。
        lineWidth: 5,
        // 字体粗细。从 `v5.0.1` 开始支持。
        fontWeight: 'normal',
        // 字体风格。从 `v5.0.1` 开始支持。
        fontStyle: 'normal',
        // 字体系列。从 `v5.0.1` 开始支持。
        fontFamily: 'sans-serif',
      },
    }
  },
  created() {

  },
  mounted() {
    this.getClassSunburst()
  },
  methods: {
    setChartColor(chartData) {
      const colors = ['#2D9BFF', '#39BFFF', '#76C2FF', '#5F57FC',
        '#2D9BFF', '#92FFFF', '#ACFF96', '#7583FF', '#73C3FF',
        '#5477FE', '#5F57F9', '#7982FE', '#4F78FE', '#2B9DFF',
        '#61FEFF', '#937FE6', '#2B56D3', '#87E7AA', '#937FE6', '#FF9B97', '#8f23f5', '#0576ea', '#2cb8cf', '#8A7EE0', '#2cb8cf', '#4e70f0',
        '#1fa3de', '#bbc951', '#FFC14B', '#b785a6',
        '#04E0F3', '#682EFC', '#35A7FE', '#0DC09F', '#682EFC', '#ED6663', '#8f23f5', '#0576ea',
        '#2cb8cf', '#8A7EE0', '#2cb8cf', '#4e70f0', '#1fa3de', '#bbc951', '#FFC14B', '#b785a6',
      ]
      if (chartData.length > 0) {
        chartData.forEach((c, index) => {
          const colorIndex = index >= colors.length ? 0 : index
          const itemStyle = {
            color: colors[colorIndex],
          }
          c.itemStyle = itemStyle
          if (c.children) {
            if (c.children.length > 0) {
              this.setChartColor(c.children)
            }
          }
        })
      }
    },
    getClassSunburst() {
      GetClassSunburst()
        .then(res => {
          this.option.series.data = res.data.data
          // itemStyle
          const colors = ['#2D9BFF', '#39BFFF', '#76C2FF', '#5F57FC',
            '#2D9BFF', '#92FFFF', '#ACFF96', '#7583FF', '#73C3FF',
            '#5477FE', '#5F57F9', '#7982FE', '#4F78FE', '#2B9DFF',
            '#61FEFF', '#937FE6', '#2B56D3', '#87E7AA', '#937FE6', '#FF9B97', '#8f23f5', '#0576ea', '#2cb8cf', '#8A7EE0', '#2cb8cf', '#4e70f0',
            '#1fa3de', '#bbc951', '#FFC14B', '#b785a6',
            '#04E0F3', '#682EFC', '#35A7FE', '#0DC09F', '#682EFC', '#ED6663', '#8f23f5', '#0576ea',
            '#2cb8cf', '#8A7EE0', '#2cb8cf', '#4e70f0', '#1fa3de', '#bbc951', '#FFC14B', '#b785a6',
          ]
          const chartData = res.data.data
          const a = this.setChartColor(chartData)
          const option = {
            series: {
              type: 'sunburst',
              data: res.data.data,
              radius: [0, '95%'],
              sort: undefined,
              emphasis: {
                focus: 'ancestor',
              },
              label: {
                fontSize: 12,
                color: '#5e5873',
              },
              levels: [
                {},
                {
                  r0: '5%',
                  r: '20%',
                  itemStyle: {
                    borderWidth: 2,
                  },
                  label: {
                    rotate: 'tangential',
                  },
                },
                {
                  r0: '20%',
                  r: '40%',
                  itemStyle: {
                    borderWidth: 2,
                  },
                  label: {
                    align: 'right',
                  },
                },
                {
                  r0: '40%',
                  r: '60%',
                  label: {
                    position: 'outside',
                    padding: 3,
                    silent: false,
                  },
                  itemStyle: {
                    borderWidth: 5,
                  },
                },
              ],
            },
          }
          if (chartData.length > 0) {
            const chart = echarts.init(document.getElementById('chart'))
            chart.setOption(option)
          }
        })
    },
  },
}
</script>

<style scoped>

</style>
