<template>

  <b-card-body>
    <div sytle="height:480px;">
      <div v-show="classChart.series.length > 0">
        <vue-apex-charts
          v-if="init"
          type="pie"
          height="260"
          class="mt-0 mb-0"
          :options="classChart.chartOptions"
          :series="classChart.series"
        />
      </div>
    </div>
    <el-empty
      v-show="classChart.series.length == 0"
      description="暂无数据"
      size="100"
    />
  </b-card-body>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import { GetLabelSatisfaction } from '@/api/system/privacy/privacy'

export default {
  components: { VueApexCharts },
  data() {
    return {
      init: false,
      classChart: {
        // series: [690, 258, ],
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          // labels: ['一般个人信息', '敏感个人信息'],
          labels: [],
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
          },
          stroke: {
            width: 0,
          },
          colors: ['#826af9', '#d2b0ff', '#2D9BFF'],
        },
      },
    }
  },
  created() {
    this.getLabelSatisfaction()
  },
  methods: {
    getLabelSatisfaction() {
      GetLabelSatisfaction().then(res => {
        const resData = res.data
        resData.data.forEach(r => {
          this.classChart.series.push(r.Count)
          this.classChart.chartOptions.labels.push(r.name)
        })
        this.init = true
      })
    },
  },
}
</script>
