import {
  downloadByGet, get, post, Delete,
} from '@/libs/axios'

// 分页获取隐私字段列表
export const GetPrivacyFieldListByPage = params => get('/privacy/field/page', params)

export const QueryInfo = id => get(`/privacy/field/info?id=${id}`)
// 创建字段
export const CreateField = params => post('/privacy/field/create', params)
// 更新字段
export const UpdateField = params => post('/privacy/field/update', params)
// 删除字段
export const DeleteFieldById = params => Delete('/privacy/field/delete', params)

// 查询隐私字段下拉
export const GetPrivacyFieldOptionsList = params => post('/db/privacyField/QueryFieldOption', params)

// 获取所有分类
export const GetAllClass = () => get('/privacy/class/tree')
// 创建分类
export const CreateClass = params => post('/db/privacyField/CreateClass', params)
// 更新分类
export const UpdateClass = params => post('/db/privacyField/UpdateClass', params)
// 删除分类
export const DeleteClassById = params => post('/db/privacyField/DeleteClassById', params)
// 获取所有分类
export const GetClassOptions = () => get('/privacy/class/select')

// 导入隐私字段模板
export const ImportPrivacyField = params => post('/privacy/field/import', params)
// 导出字段模板
export const ExportPrivacyField = () => downloadByGet('/privacy/field/export')
// 下载导入模板
export const DownloadImportTemplateFile = () => downloadByGet('/privacy/field/template/download')

// 分页获取隐私字段标签列表
export const GetLabelPage = params => post('/db/privacyField/PageLabel', params)
// 创建标签
export const CreateLabel = params => post('/db/privacyField/CreateLabel', params)
// 更新标签
export const UpdateLabel = params => post('/db/privacyField/UpdateLabel', params)
export const saveOrUpdateLabel = form => {
  const submitUrl = form.id ? '/db/privacyField/UpdateLabel' : '/db/privacyField/CreateLabel'
  return post(submitUrl, form)
}
// 删除标签
export const DeleteLabelById = params => post('/db/privacyField/DeleteLabel', params)
// 标签下拉列表
export const GetLabelOptions = () => get('/db/privacyField/GetLabelOptions')
export const InitField = () => get('/enterprise/init/field')
export const GetClassFieldTree = () => get('/db/privacyField/GetClassFieldTree')

// 创建/修改标签维度
export const saveOrUpdateDimension = form => {
  const submitUrl = form.id ? '/privacyField/label/UpdateDimension' : '/privacyField/label/CreateDimension'
  return post(submitUrl, form)
}
// 删除标签维度
export const DeleteDimensionById = params => post('/privacyField/label/DeleteDimensionById', params)
// 获取所有标签维度
export const AllDimension = () => get('/privacyField/label/AllDimension')
// 标签维度树下拉
export const GetLabelDimensionTree = () => get('/privacyField/label/GetLabelDimensionTree')
export const GetTopClassSatisfaction = () => get('/db/privacyField/GetTopClassSatisfaction')
export const GetClassSunburst = () => get('/db/privacyField/GetClassSunburst')
export const GetSensitiveSatisfaction = () => get('/db/privacyField/GetSensitiveSatisfaction')
export const GetLabelSatisfaction = () => get('/db/privacyField/GetLabelSatisfaction')
