<!-- eslint-disable import/extensions -->
<template>
  <b-card no-body>
    <!--
    <b-card-header>
      <b-card-title class="mb-1">
        隐私字段图
      </b-card-title>
    </b-card-header> -->

    <b-card-body
      id="main"
      v-loading="loading"
      :style="{height: chartHeight+'px'}"
    >
      <e-charts
        v-if="tree.series[0].data.length > 0"
        ref="tree"
        theme="ovilia-green"
        :options="tree"
        @click="changeHeight"
      />
      <el-empty
        v-else
        description="暂无数据"
        size="100"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'

import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/tree'
import { GetClassFieldTree } from '@/api/system/privacy/privacy'
import theme from './theme.json'

ECharts.registerTheme('ovilia-red2', theme)

export default {
  name: 'OrgStatics',
  components: {
    ECharts,
    BCard,
    BCardHeader,
    BCardBody,
  },
  data() {
    return {
      loading: false,
      chartHeight: 440,
      tree: {
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove',
        },
        series: [
          {
            type: 'tree',
            data: [],
            // roam: true,
            top: '1%',
            left: '-10%',
            bottom: '1%',
            right: '10%',
            symbolSize: 10,
            initialTreeDepth: 3,
            label: {
              position: 'left',
              verticalAlign: 'middle',
              align: 'right',
              fontSize: 10,
            },
            // lineStyle: {
            //   color: 'red',
            //   width: 20,
            // },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left',
              },
            },

            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750,
          }],
        nodesTotal: 1,
      },
      nodeCount: 1,
    }
  },
  created() {

    // this.changeHeight()
  },
  mounted() {
    this.getClassFieldTree()
  },
  methods: {
    getClassFieldTree() {
      this.loading = true
      GetClassFieldTree().then(res => {
        this.loading = false
        // this.tree.series[0].data = res.data.data
        const treeData = res.data.data
        if (treeData) {
          const allData = {
            name: '',
            children: treeData,
            // symbolSize: 0,
          }
          if (treeData.length > 1) {
            treeData.forEach(t => {
              t.lineStyle = {
                color: '#fff',
                width: 0,
              }
            })
            this.tree.series[0].data.push(allData)
            this.tree.series[0].left = '-22%'
          } else {
            this.tree.series[0].data = treeData
            this.tree.series[0].left = '10%'
          }
        }
        this.initNodeCount(treeData, 1)
        this.computeHeight(this.nodeCount)
      })
    },
    changeHeight() {
      let allNode = 0
      // eslint-disable-next-line no-underscore-dangle
      const nodes = this.$refs.tree.chart._chartsViews[0]._data._graphicEls
      nodes.forEach(node => {
        if (node !== undefined) {
          allNode += 1
        }
      })
      this.computeHeight(allNode)

      this.$nextTick(() => {
        this.$refs.tree.resize()
      })
    },
    initNodeCount(json, level) {
      this.nodeCount += json.length
      if (level < this.tree.series[0].initialTreeDepth) {
        if (json.length > 0) {
          json.forEach(c => {
            const nextLevel = level + 1
            if (c.children !== undefined && c.children !== null) {
              this.initNodeCount(c.children, nextLevel)
            }
          })
        }
      }
    },
    computeHeight(nodeCount) {
      const h = 13 * nodeCount < 440 ? 440 : 13 * nodeCount
      this.chartHeight = h
    },
  },
}
</script>

<style>
.echarts{
  width:100%;
  height:100%;
}
</style>
