<template>
  <b-card
    v-loading="loading"
    no-body
  >
    <b-card-header>
      <el-col
        :span="20"
        class="font-medium-1 mt-10"
      >
        <span class="title">字段统计信息</span>
      </el-col>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row v-if="statisticsItems.length > 0" class="align-content-center h-100 mb-1">
        <b-col
          v-for="(item,index) in statisticsItems"
          :key="index"
          xl="2"
          sm="3"
          :class="item.customClass"
          style="margin-left: 15px;margin-right: 0px;margin-bottom: 10px !important;"
        >
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
                <i
                  class="iconfont"
                  :class="item.icon"
                  style="font-size: 24px"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4
                class="font-weight-bolder mb-0"
                style="font-size:16px"
              >
                {{ item.title }}
              </h4>
              <b-card-text class="mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
      <el-empty v-else description="暂无数据" />
    </b-card-body>
  </b-card>
</template>

<script>
import { GetTopClassSatisfaction } from '@/api/system/privacy/privacy'
import {
  BAvatar,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BMedia,
  BMediaAside, BMediaBody,
  BRow,
} from 'bootstrap-vue'

export default {
  name: 'FieldTotal',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      loading: false,
      statisticsItems: [
        // {
        //   icon: 'BoxIcon',
        //   color: 'light-primary',
        //   title: 3,
        //   subtitle: '个人通用隐私字段数',
        //   customClass: 'mb-2 mb-xl-0',
        // },
        // {
        //   icon: 'BoxIcon',
        //   color: 'light-warning',
        //   title: 3,
        //   subtitle: '医疗隐私字段数',
        //   customClass: 'mb-2 mb-xl-0',
        // },
        // {
        //   icon: 'icon-yuanshuju-ziduanguanli',
        //   color: 'light-info',
        //   title: 0,
        //   subtitle: '其他字段数',
        //   customClass: 'mb-2 mb-xl-0',
        // },
        // {
        //   icon: 'icon-minganguanjianzi',
        //   color: 'light-success',
        //   title: 0,
        //   subtitle: '总字段数',
        //   customClass: '',
        // },
      ],
    }
  },
  created() {
    this.getTopClassSatisfaction()
  },
  methods: {
    getTopClassSatisfaction() {
      this.loading = true
      GetTopClassSatisfaction().then(res => {
        this.loading = false
        const resData = res.data
        const colors = ['light-primary', 'light-warning', 'light-info', 'light-success']
        if (resData.data.topClass) {
          resData.data.topClass.forEach((r, index) => {
            this.statisticsItems.push({
              icon: 'BoxIcon',
              color: colors[index],
              title: r.Count,
              subtitle: r.name,
              customClass: 'mb-2 mb-xl-0',
            })
          })
          this.statisticsItems.push({
            icon: 'BoxIcon',
            color: 'light-primary',
            title: resData.data.total,
            subtitle: '总字段数',
            customClass: 'mb-2 mb-xl-0',
          })
        }
      })
    },
  },
}
</script>
