<template>
  <el-dialog :title="title"
             :visible.sync="dialogVisible"
             width="620px"
             @close="dialogClose"
             @open="dialogOpen">
    <el-form v-if="dialogVisible"
             ref="form"
             v-loading="loading"
             :model="form"
             class="field-class-save-form"
             label-width="100px">

      <el-form-item label="分类名称"
                    prop="className"
                    :rules="[ { required: true, message: '分类名称不能为空', trigger: 'change' }]">
        <el-input  v-model="form.className"
                  type="text"
                  maxlength="20" />
      </el-form-item>

    </el-form>
    <div slot="footer"
         class="dialog-footer">
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button type="primary"
                 :disabled="loading"
                 @click="submitForm">
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { CreateClass, UpdateClass } from '@/api/system/privacy/privacy'
import { error, success } from '@core/utils/utils'

export default {
  data () {
    return {
      dialogVisible: false,
      loading: false,
      title: '新增子分类',
      form: {
        id: '',
        className: '',
        parentId: '',
        parentName: '',
      },
    }
  },
  methods: {
    dialogOpen () {
      if (this.form.id) {
        this.title = '修改当前分类'
      } else if (this.form.parentId) {
        this.title = `新增${this.form.parentName}的子目录`
      } else {
        this.title = '新增一级分类'
      }
    },
    submitForm () {
      // eslint-disable-next-line consistent-return
      this.$refs.form.validate(valid => {
        // eslint-disable-next-line no-empty
        if (valid) {
          this.loading = true
          const submitData = {
            className: this.form.className,
            parentId: this.form.parentId,
          }
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const i in submitData) {
            submitData[i] = this.form[i]
          }
          submitData.parentId = this.form.parentId ? this.form.parentId : 0
          if (this.form.id) {
            submitData.id = this.form.id
            UpdateClass(submitData).then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                this.$emit('updateTreeNode', this.form)
              } else {
                error(resData.msg)
              }
            })
          } else {
            CreateClass(submitData).then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                const newNode = {
                  id: resData.data.id ? resData.data.id : 0,
                  is_show: false,
                  label: submitData.className,
                  parentId: submitData.parentId,
                }
                this.$emit('addTreeNode', newNode)
              } else {
                error(resData.msg)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    dialogClose () {
      this.form = {
        id: '',
        className: '',
        parentId: '',
        parentName: '',
      }
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style scoped>
</style>
