<template>
  <b-card-body>
    <div v-show="total > 0">
      <vue-apex-charts
        v-if="init"
        type="pie"
        height="260"
        class="mt-0 mb-0"
        :options="classChart.chartOptions"
        :series="classChart.series"
      />
    </div>
    <el-empty
      v-show="total === 0"
      description="暂无数据"
      size="100"
    />
  </b-card-body>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { GetSensitiveSatisfaction } from '@/api/system/privacy/privacy'

export default {
  name: 'SensitivePie',
  components: { VueApexCharts },
  data() {
    return {
      init: false,
      classChart: {
        // series: [690, 258, ],
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['一般个人信息', '敏感个人信息'],
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
          },
          stroke: {
            width: 0,
          },
          colors: ['#826af9', '#d2b0ff'],
        },
      },
      total: 0,
    }
  },
  created() {
    this.getSensitiveSatisfaction()
  },
  methods: {
    getSensitiveSatisfaction() {
      GetSensitiveSatisfaction().then(res => {
        const resData = res.data
        this.classChart.series.push(resData.data.generalSaveCount)
        this.classChart.series.push(resData.data.sensitiveSaveCount)
        this.total = resData.data.total
        this.init = true
      })
    },
  },
}
</script>

<style scoped>

</style>
