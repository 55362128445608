<template>
  <b-row class="match-height">
    <b-col cols="12">
      <FieldTotal />
    </b-col>
    <b-col cols="8">
      <b-card no-body>
        <b-card-header>
          <el-col
            :span="12"
            class="font-medium-1 mt-10"
          >
            <span class="title">分类的字段数量占比</span>
          </el-col>
        </b-card-header>

        <b-card-body>
          <FieldSunburst />
          <!--                    <el-empty  description="暂无数据" size="100"/>-->
        </b-card-body>
      </b-card>

    </b-col>
    <b-col cols="4">
      <b-card no-body>
        <b-card-header>
          <el-col
            :span="12"
            class="font-medium-1 mt-10"
          >
            <span class="title">信息敏感性数量占比</span>
          </el-col>
        </b-card-header>
        <SensitivePie />

      </b-card>
      <b-card no-body>
        <b-card-header>
          <el-col
            :span="12"
            class="font-medium-1 mt-10"
          >
            <span class="title">数据标签数量占比</span>
          </el-col>
        </b-card-header>
        <LabelPie />
      </b-card>
    </b-col>
    <b-col cols="12">
      <b-card no-body>
        <b-card-header>
          <el-col
            :span="12"
            class="font-medium-1 mt-10"
          >
            <span class="title">字段图谱</span>
          </el-col>
        </b-card-header>

        <PrivateFieldsChart />
      </b-card>

    </b-col>
  </b-row>
</template>

<script>
import FieldTotal from './FieldTotal.vue'
import FieldSunburst from './FieldSunburst.vue'
import SensitivePie from './SensitivePie.vue'
import LabelPie from './LabelPie.vue'
import PrivateFieldsChart from '../PrivateFieldsChart.vue'

export default {
  name: 'FieldStatistics',
  components: {
    FieldTotal, FieldSunburst, PrivateFieldsChart, SensitivePie, LabelPie,
  },
}
</script>

<style lang="scss">
    /*title*/
    .title{
        border-left: solid 6px #0d40b2;
        padding-left: 8px;
        margin-bottom: 2px;
    }

    .el-empty{
        padding: 10px;
    }

    .card-body{
        padding: 10px;
    }
</style>
