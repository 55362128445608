<template>
  <el-dialog :title="title"
             :visible.sync="dialogVisible"
             width="620px"
             @close="dialogClose"
             @open="dialogOpen">
    <el-form v-if="dialogVisible"
             ref="form"
             v-loading="loading"
             :model="form"
             class="field-class-save-form"
             label-width="100px">

      <el-form-item label="维度名称"
                    prop="dimensionName"
                    :rules="[ { required: true, message: '维度名称不能为空', trigger: 'change' }]">
        <el-input  v-model="form.dimensionName"
                  type="text"
                  maxlength="20" />
      </el-form-item>

    </el-form>
    <div slot="footer"
         class="dialog-footer">
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button type="primary"
                 :disabled="loading"
                 @click="submitForm">
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { saveOrUpdateDimension } from '@/api/system/privacy/privacy'
import { error, success } from '@core/utils/utils'

export default {
  data () {
    return {
      dialogVisible: false,
      loading: false,
      title: '',
      form: {
        id: '',
        dimensionName: '',
        parentId: '',
        parentName: '',
      },
    }
  },
  methods: {
    dialogOpen () {
      if (this.form.id) {
        this.title = '修改当前维度'
      } else if (this.form.parentId) {
        this.title = `新增${this.form.parentName}的子维度`
      } else {
        this.title = '新增一级维度'
      }
    },
    submitForm () {
      // eslint-disable-next-line consistent-return
      this.$refs.form.validate(valid => {
        // eslint-disable-next-line no-empty
        if (valid) {
          this.loading = true
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          saveOrUpdateDimension(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              if (this.form.id) {
                this.$emit('updateTreeNode', this.form)
              } else {
                const newNode = {
                  id: resData.data.id ? resData.data.id : 0,
                  is_show: false,
                  label: resData.data.dimensionName,
                  parentId: resData.data.parentId,
                }
                this.$emit('addTreeNode', newNode)
              }
            } else {
              error(resData.msg)
            }
          })
        } else {
          return false
        }
      })
    },
    dialogClose () {
      this.form = {
        id: '',
        dimensionName: '',
        parentId: '',
        parentName: '',
      }
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style scoped>
</style>
